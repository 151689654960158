import React, { useState, useContext, useEffect } from "react";
import "./App.css";
import I_know_its_an_image_im_rushed from "./I_know_its_an_image_im_rushed.png";
import simpleLogo from "./simpleLogo.png";
import { TokenContext } from "./TokenContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { BASE_URL } from "./Base_url";



export default function SignIn({ onSignIn }) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { token, setToken } = useContext(TokenContext);
  const [display, setDisplay] = useState("website_display");
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const pickImage = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onloadend = () => {
      setImage({
        uri: reader.result,
        base64: reader.result.split(',')[1],
        fileName: file.name,
      });
    };
  
    if (file) {
      reader.readAsDataURL(file);
    }
  };
    


  const upload_image = async () => {
    if (!image?.base64) {
        alert('No image selected');
        return null;
      }
    
      const data = {
        image: image.base64, // Use the Base64 data directly
        filename: image.fileName,
      };

    let jsonData;
    try {
      jsonData = JSON.stringify(data);
    } catch (error) {
      console.error("Error creating JSON from data:", error);
      return null;
    }

    const response = await fetch(`${BASE_URL}/api/wotd/image`, {
      method: "POST",
      body: jsonData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(response);

    const image_url = await response.json();
    console.log(image_url);

    if (response.status === 200) {
      return image_url;
    } else {
      alert("There was an error uploading the profile picture");
      return null;
    }
  };


  const createAccount = async() => {
    // Perform sign-in logic here (e.g., make API call)

    let image_url = await upload_image();
    console.log("Image URL:", image_url);

    if (image_url === null) {
      alert("Please Upload a profile picture");
      return;
    }
    if (username === "") {
      alert("Please enter a username");
      return;
    }
    if (email === "") {
      alert("Please enter an email");
      return;
    }
    if (password === "") {
      alert("Please enter a password");
      return;
    }


    let data = {
      username: username,
      password: password,
      email: email,
      profile_picture: image_url,
      creation_date: new Date(),
      disabled: false

    };

    let jsonData;

    try{
      jsonData = JSON.stringify(data);
    } catch(error) {
      console.error("Error:", error);
      return null;

    }

    try {
      const response = await fetch(`${BASE_URL}/api/user`, {
        method: "POST",
        body: jsonData,
        headers: {
          'accept': 'application/json',
          "Content-Type": "application/json",
        },
      });

      if(response.status === 200) {
        setSuccess(true);
        setErrorMessage("Accound Sucessfully Created");
      } else if (response.status === 400) {
        setErrorMessage("Username already taken");
      }
    } catch (error) {
      console.error("Error:", error);
        setErrorMessage("Username already taken");
      }
  };

  useEffect(() => {
    if(success) {
      handleSignIn();
    }
  }, [success]);




  const handleSignIn = () => {
    // Perform sign-in logic here (e.g., make API call)
    fetch(`${BASE_URL}/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `username=${username}&password=${password}&grant_type=password`,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.access_token && data.refresh_token) {
          // If sign-in is successful, call onSignIn
          console.log(data.access_token);
          console.log(data.refresh_token);
          setToken(data.access_token);
          localStorage.setItem('refreshToken', data.refresh_token.toString());
          onSignIn();
        } else {
          // Handle sign-in failure (e.g., show an error message)
          console.error("Sign-in failed");
          setErrorMessage("Error incorrect username or password");
        }
      })
      .catch((error) => {
        // Handle request failure (e.g., network error)
        console.error("Error:", error);
        setErrorMessage("Error incorrect username or password");
      });
  };

  return (
    <div className="whynot">
      {display === "login"? (
        <>
       
      <img
        className="logo"
        src="https://imagestoragebeta.s3.us-west-1.amazonaws.com/DoingDoneFullLogoWBG+(3.5+x+1+in).png"
        alt="DoingDone Logo"
        onClick={() => setDisplay("website_display")}
      />
      <p className="warning">{errorMessage}</p>
      <input
        id="username"

        className="sign"
        placeholder="Username"
        value={username}
        onChange={(e) => {
            setUsername(e.target.value);
          }}
      />
      <div>
      <input
        id="password"
        className="sign"
        placeholder="Password"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(e) => {
            setPassword(e.target.value);
          }}
      />
      <button onClick={() => setShowPassword(!showPassword)}className="move-button">
        <FontAwesomeIcon icon={!showPassword? faEyeSlash : faEye} size="2xl"
                          style={{ color: "ivory" }}/>
      </button>
      </div>
      <div style={{ justifyContent: "center", display: "flex", flexDirection: "row", alignItems: "center"}}>
      <button className="sign_in_butt_inital" onClick={handleSignIn}>
        <span className="text">Sign In</span>
      </button>
      <button className="sign_in_create" onClick={() => setDisplay("createAcc")}>
        <span className="text">Create</span>
      </button>
      </div>
      </>
      ): display === "createAcc" ? (
        <>
       
      <img
        className="logo"
        src="https://imagestoragebeta.s3.us-west-1.amazonaws.com/DoingDoneFullLogoWBG+(3.5+x+1+in).png"
        alt="DoingDone Logo"
        onClick={() => setDisplay("website_display")}
      />
      <p className={success ? "created" : "warning"}>{errorMessage}</p>
      <div style = {{justifyContent: "center", display: "flex", flexDirection: "row", alignItems: "center"}}>
      <div  className="profile_image_box" onClick={() => document.getElementById('fileInput').click()}>
        {image ? (
            <img src={image.uri} alt="Picked" style={{ width: "100%", height: "100%", borderRadius: 10 }} />
        ) : (
            <p className="welcome">Profile Pic</p>
        )}
        <input
            id="fileInput"
            type="file"
            style={{ display: 'none' }}
            onChange={pickImage}
            accept="image/*"
        />
        </div>

        <input
        id="username"
        maxLength="8"
        className="sign"
        placeholder="Username"
        value={username}
        onChange={(e) => {
            setUsername(e.target.value);
          }}
      />
      </div>
      <input
        id="email"
        className="sign"
        placeholder="Email"
        value={email}
        onChange={(e) => {
            setEmail(e.target.value);
          }}
      />
      <div>
      <input
        id="password"
        className="sign"
        placeholder="Password"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(e) => {
            setPassword(e.target.value);
          }}
      />
      <button onClick={() => setShowPassword(!showPassword)}className="move-button">
        <FontAwesomeIcon icon={!showPassword? faEyeSlash : faEye} size="2xl"
                          style={{ color: "ivory" }}/>
      </button>
      </div>
      <div style={{justifyContent: "center", display: "flex", flexDirection: "row", alignItems: "center"}}>
      <button className="sign_in_create_inverse" onClick={createAccount}>
        <span className="text">Create</span>
      </button>
      <button className="sign_in_butt_inital_inverse" onClick={() => setDisplay("login")}>
        <span className="text">Sign In</span>
      </button>
      </div>
      </>     

      ):(
        <div>
          <div style = {{display: "flex", justifyContent: "space-between", padding: "10px"}}>
            <img src={simpleLogo} alt="Description" width="7%" height="7%"/>
            <button className="sign_in_butt_inital_real" onClick={() => setDisplay("login")}>
              Sign In
            </button>
          </div>
          <img src={I_know_its_an_image_im_rushed}alt="Description" width="100%" height="100%" />
        </div>
      )}
    </div>
  );
}