import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import useToken  from "./TokenContext";
import { BASE_URL } from "./Base_url.js";
import pomoSound from "./level-up-191997.mp3";
import { useAudio } from './AudiContext';
import { min } from 'date-fns';




const PomodoroContext = createContext();

export function usePomodoro() {
  return useContext(PomodoroContext);
}

export const PomodoroProvider = ({ children }) => {
    const [mainTimer, setMainTimer] = useState(   25   * 60);
    const [breakTimer, setBreakTimer] = useState(5 * 60);
    const [isRunning, setIsRunning] = useState(false);
    const [isBreak, setIsBreak] = useState(false);
    const [isFirstRun, setIsFirstRun] = useState(true);
    const { token, refreshToken, profile, updatePomosThisWeek } = useToken();
    const [pomostoday, setPomostoday] = useState(0);
    const canvasRef = useRef(null);
    const intervalIdRef = useRef(null);
    const { playSound } = useAudio();



  const upload_pomo = async () => {
    const pomoObject = {
      username: profile[0].username,
      pomodoro_id: "ha",
      length:   25  ,
      completed: true,
      completion_date: "2021-10-10",
      creation_date: "2021-10-10"
    };

    let jsonData;
    try {
      jsonData = JSON.stringify(pomoObject);
    } catch (error) {
      console.error('Error creating JSON from data:', error);
      return null;
    }

    try {
      const response = await fetch(`${BASE_URL}/api/pomodoro`, {
        method: "POST",
        body: jsonData,
        headers: {
          'accept': 'application/json',
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        try {
          await refreshToken();
        } catch (error) {
          // Handle token refresh failure
          throw error;
        }
      } else if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isRunning) {
      const expectedEndTime = Date.now() + (isBreak ? breakTimer : mainTimer) * 1000;
  
      intervalIdRef.current = setInterval(() => {
        const now = Date.now();
        const timeLeft = Math.max(expectedEndTime - now, 0);
        const newTimerValue = Math.ceil(timeLeft / 1000);
  
        if (isBreak) {
          setBreakTimer(newTimerValue);
        } else {
          setMainTimer(newTimerValue);
        }
  
        if (newTimerValue <= 0) {
          clearInterval(intervalIdRef.current);
          localStorage.removeItem('startTime');
          localStorage.removeItem('isBreak');
          setIsRunning(false); // Stop the timer
          setBreakTimer(5 * 60);
          setMainTimer( 25  * 60);
          playSound();
          if (!isBreak) {
            setIsBreak(true);
            upload_pomo();
            updatePomosThisWeek();
            setPomostoday(pomostoday + 1);
          } else {
            setIsBreak(false);
          }

        }    
      }, 1000);
    }
  
    return () => clearInterval(intervalIdRef.current);
  }, [isRunning, isBreak, mainTimer, breakTimer]);
    
    






  

  useEffect(() => {
    if (isRunning && isFirstRun) {
      setIsFirstRun(false);
    } else if (!isRunning && !isFirstRun) {
      setIsFirstRun(true);
    }
  }, [isRunning, isFirstRun, mainTimer, breakTimer, isBreak]);


  const timer = isBreak ? breakTimer : mainTimer;
  const maxTime = isBreak ? 5 * 60 :    25    * 60;
  const progress = timer / maxTime;
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  useEffect(() => {
    if (profile && profile[0]) {
      fetch(`${BASE_URL}/api/pomodoro/done_today?username=${profile[0].username}`, {
        method: "GET",
        headers: {
          "accept": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => setPomostoday(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [profile]);


  

  useEffect(() => {
    const savedStartTime = localStorage.getItem('startTime');
    const savedIsBreak = localStorage.getItem('isBreak') === 'true';
    if (savedStartTime) {
      const elapsedSeconds = Math.floor((Date.now() - savedStartTime) / 1000);
      if (savedIsBreak) {
        setBreakTimer((5 * 60) - elapsedSeconds);
        setIsBreak(true);
      } else {
        setMainTimer((  25   * 60) - elapsedSeconds);
        setIsBreak(false);
      }
      setIsRunning(true);
    }
  }, []);

  return (
    <PomodoroContext.Provider value={{ mainTimer, setMainTimer, breakTimer, setBreakTimer, isRunning, setIsRunning, isBreak, setIsBreak, pomostoday, canvasRef, progress, minutes, seconds }}>
      {children}
    </PomodoroContext.Provider>
  );
};