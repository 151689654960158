import React, { useState, useEffect, useRef } from "react";
import { Modal, TextField } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { BASE_URL } from "./Base_url.js";
import useToken from "./TokenContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronRight,
  faCircleChevronLeft,
  faCircleXmark,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {format} from 'date-fns';
import { useAudio } from './AudiContext';


export default function TaskView() {
  const [display, setDisplay] = useState("kan-ban");
  const [taskList, setTasks] = useState([]);
  const { token, refreshToken, profile, updateDoToday} =
    useToken();

  const [calendar_view, setCalendarView] = useState("do_date");
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState("todo");
  const [title, setTitle] = useState("");
  const [input_do_date, setInputDoDate] = useState(new Date());
  const [input_due_date, setInputDueDate] = useState(new Date());
  const { playSound } = useAudio();
  const [theDoDate, setTheDoDate] = useState(new Date());
  const [theDueDate, setTheDueDate] = useState(new Date());


  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()) ;
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const previousMonth = () => {
    setSelectedMonth(prev => prev - 1);
  }
  const nextMonth = () => {
    setSelectedMonth(prev => prev + 1);
  }

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const handleDragStart = (e, task) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(task));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, formattedDate) => {
    e.preventDefault();
    const taskData = JSON.parse(e.dataTransfer.getData('text/plain'));
    const updatedTasks = taskList.map((task) =>
      task.task_id === taskData.task_id ? { ...task, [calendar_view]: formattedDate } : task
    );
    setTasks(updatedTasks);
    if (calendar_view === "due_date"){

     changeDueDate(taskData.task_id, formattedDate);
    }
    else{ 
      const today = new Date();
      const today_formatted = format(today, 'yyyy-MM-dd');
      if(formattedDate === today_formatted){
        updateDoToday(taskData, false);
      } else if (taskData.do_date === today_formatted){
        updateDoToday(taskData, true);
      }
      changeDoDate(taskData.task_id, formattedDate);
    }
  };



  const changeDoDate = async (task_id, do_date) => {
 
    const response = await fetch(
      `${BASE_URL}/api/task/do_date?username=${profile[0].username}&task_id=${task_id}&do_date=${do_date}`,
      {
        method: "PUT",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );  

    if (response.status === 401) {
      try {
        await refreshToken(); 
        
      } catch (error) {
        
          // Handle token refresh failure
          throw error;
      }
    }

   

    };

    const changeDueDate = async (task_id, due_date) => {
 
        const response = await fetch(
          `${BASE_URL}/api/task/due_date?username=${profile[0].username}&task_id=${task_id}&due_date=${due_date}`,
          {
            method: "PUT",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );  
    
        if (response.status === 401) {
          try {
            await refreshToken(); 
            
          } catch (error) {
            
              // Handle token refresh failure
              throw error;
          }
        }
    
        };




    const generateID = () => {
      return '_' + Math.random().toString(36).substr(2, 9);
    };







  const renderCalendar = (type) => {
    const daysInMonth = getDaysInMonth(selectedMonth, selectedYear);
    const startDay = new Date(selectedYear, selectedMonth, 1).getDay();
    const endDay = new Date(selectedYear, selectedMonth + 1, 0).getDay();
    const prevMonthDays = getDaysInMonth(selectedMonth - 1, selectedYear);




    
  
    const calendarRows = [];
    let calendarDays = [];
  
    // Render cells for days from the previous month
    for (let i = startDay - 1; i > 0; i--) {
      calendarDays.unshift(
        <div key={`prev-month-${prevMonthDays - i + 1}`} className="day_not_month">
          <div className="day_number">{prevMonthDays - i + 1}</div>
        </div>
      );
    }

    


  
    // Render cells for each day of the current month
    for (let day = 1; day <= daysInMonth; day++) {
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = selectedMonth + 1 < 10 ? `0${selectedMonth + 1}` : selectedMonth + 1;
        const formattedDate = `${selectedYear}-${formattedMonth}-${formattedDay}`;

      calendarDays.push(
        <div key={`day-${day}`} className="day" id={`day-${day}`} onDragOver={(e) => handleDragOver(e)}
        onDrop={(e) => handleDrop(e, formattedDate)}>
          <div className="day_number">{day}</div>
          {type === "due_date" ? ( taskList
            .filter((task) => task.due_date === formattedDate)
            .map((task) => (
              <div key={task.task_id} className={task.status === "todo"? "calendar_task_container_todo" : task.status === "doing" ? "calendar_task_container_doing": "calendar_task_container_done"} draggable="true"
              onDragStart={(e) => handleDragStart(e, task)}>
                {task.title}
              </div>
            ))
        ) : (taskList
            .filter((task) => task.do_date === `${selectedYear}-${formattedMonth}-${formattedDay}`)
            .map((task) => (
              <div key={task.task_id} className={task.status === "todo"? "calendar_task_container_todo" : task.status === "doing" ? "calendar_task_container_doing": "calendar_task_container_done"} draggable="true"
              onDragStart={(e) => handleDragStart(e, task)}>
                {task.title}
              </div>
            )))}
        </div>
      );
  
      // If the current day is Sunday or the last day of the month,
      // add the row to the calendarRows array
      if (new Date(selectedYear, selectedMonth, day).getDay() === 0) {
        calendarRows.push(
          <div key={`row-${calendarRows.length}`} className="calendar_container">
            {calendarDays}
          </div>
        );
        calendarDays = [];
      }
    }
  
    // Fill the remaining cells in the last row with days from the next month
    if(endDay !== 0){

    const remainingDays = 7 - calendarDays.length;
    
    for (let i = 1; i <= remainingDays; i++) {
      calendarDays.push(
        <div key={`next-month-${i}`} className="day_not_month">
          <div className="day_number">{i}</div>
        </div>
      );
    }
    }
  
    // Add the last row to the calendarRows array
    calendarRows.push(
      <div key={`row-${calendarRows.length}`} className="calendar_container">
        {calendarDays}
      </div>
    );
  
    return calendarRows;
  };




  const changeStatus = async (task_id, status) => {
    const response = await fetch(
      `${BASE_URL}/api/task/${task_id}/status?username=${profile[0].username}&status=${status}`,
      {
        method: "PUT",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  
    if (response.status === 401) {
      try {
        await refreshToken();
      } catch (error) {
        // Handle token refresh failure
        throw error;
      }
    }
  
    if (response.ok) {
      // Find the task that was updated
      const task = taskList.find((task) => task.task_id === task_id);
  
      // Update the task's status
      const updatedTask = { ...task, status };
  
      if (status === "done") {
        updatedTask.done = true;
      }
  
      // Update the state
      setTasks((tasks) => tasks.map((task) => task.task_id === task_id ? updatedTask : task));
    }
  };

  const deleteTask = async (task_id) => {
    const response = await fetch(
      `${BASE_URL}/api/task/${task_id}?username=${profile[0].username}`,
      {
        method: "DELETE",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 401) {
      try {
        await refreshToken();
      } catch (error) {
        // Handle token refresh failure
        throw error;
      }
    }

    if (response.ok) {
        setTasks((tasks) => tasks.filter((task) => task.task_id !== task_id));
    }
  };

  const onChangeDoDate = (date) => {

    const adjustedDate = new Date(date.setMinutes(date.getMinutes() - date.getTimezoneOffset()));
    const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
    setInputDoDate(formattedDate);
    setTheDoDate(date);
 
  };
  
  const onChangeDueDate = (date) => {
 
    const adjustedDate = new Date(date.setMinutes(date.getMinutes() - date.getTimezoneOffset()));
    const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
    setInputDueDate(formattedDate);
    setTheDueDate(date);

  };

  const isDateFormattedCorrectly = (date) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(date);
  };
  

  const upload_task = async () => {
    // Create a new FormData instance

    if(title === "" || title === null){
      return;
    }

    let lets_do_date = input_do_date;
    let lets_due_date = input_due_date;
  

    if (!isDateFormattedCorrectly(input_do_date)) {
      console.log("do_date is not formatted correctly");
      const adjustedDate = new Date(input_do_date.setMinutes(input_do_date.getMinutes() - input_do_date.getTimezoneOffset()));
      const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
      setInputDoDate(formattedDate);
      lets_do_date = formattedDate;
      
    }
    if (!isDateFormattedCorrectly(input_due_date)) {
      console.log("due_date is not formatted correctly");
      const adjustedDate = new Date(input_due_date.setMinutes(input_due_date.getMinutes() - input_due_date.getTimezoneOffset()));
      const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
      setInputDueDate(formattedDate);
      lets_due_date = formattedDate;
    }

    let stat = "todo";

    if (status.value != null){
      stat = status.value;
    }
   
    

    

    // Create the wotd object
    const taskObject = {
      username: profile[0].username,
      task_id: generateID(),
      title: title,
      class_name: "string",
      due_date: lets_due_date,
      do_date: lets_do_date,
      status: stat,
      done: false, // replace with actual creation_date
    };

    // Append the wotd object to the FormData instance
    let jsonData;
    try {
      jsonData = JSON.stringify(taskObject);
    } catch (error) {
      console.error("Error creating JSON from data:", error);
      return null;
    }

    try {
      const response = await fetch(`${BASE_URL}/api/task`, {
        method: "POST",
        body: jsonData,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        // Unauthorized
        await refreshToken();
        // Retry the request
      }

      const responseBody = await response.json();
      if (response.status === 200) {
       
        // Success
        setTasks((tasks) => [responseBody, ...tasks]); // Extract data from the response
      }
    } catch (error) {
      console.error(error);
    }
    setVisible(false);

    setTitle("");
    setStatus({ value: "todo", label: "Todo" });
    
  };






  useEffect(() => {
    const fetchTasks = async () => {
      if (profile && profile[0]) {
        fetch(`${BASE_URL}/api/task?username=${profile[0].username}`, {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((fetchedData) => setTasks(fetchedData))
          .catch((error) => console.error("Error:", error));
      }
    };

    fetchTasks();
  }, [profile]);

  //THIS IS GOING TO BE FOR THE DO AND DUE DATES
  



  return (
    <div
      className="haha"
      style={{ flexGrow: 1, backgroundColor: "#2b2b2b", width: "100%" }}
    >
      <Modal open={visible} onClose={() => setVisible(false)} style = {{width: "100%", height: "100%", justifyContent: "center", alignItems: "center", display: "flex"}}>
        <div className="actuall_add_task_container">
          <TextField
            maxLength={24}
            label="Title"
            value={title}
            style = {{width: "80%", backgroundColor: "ivory", color: "#2b2b2b"}}
            onChange={(e) => setTitle(e.target.value)}
            className="assign_task_title_container"
          />
          <div className="task_details_container">
            <div className="task_details_row">
              <div className="date_picker_container_titles">DO date</div>
              <div className="date_picker_container_titles">Due date</div>
              
            </div>
            <div className="task_details_row">
              <div className="date_picker_container">
              <DatePicker
                className="date_pucker"
                selected={theDoDate}
                onChange={onChangeDoDate}
          
              /></div>
             <div className="date_picker_container">
              <DatePicker
               className = "date_pucker"
               selected={theDueDate}
                onChange={onChangeDueDate}
    
              />
              </div>
            </div>
          
          <div className="task_details_row">
          <Select
            className="select_container"
            placeholder="Status"
            value={status}
            onChange={(selectedOption) => setStatus(selectedOption)}
            options={[
              { label: "To Do", value: "todo" },
              { label: "Doing", value: "doing" },
              { label: "Done", value: "done" },
            ]}
          />
            <button className="sign_in_butt_task" onClick={upload_task}>
            Submit
          </button>
          </div>
          </div>
        </div>
      </Modal>

      <div className="quercus">
        <button
          className={`kan_ban_headers`}
          style={{ opacity: display === "kan-ban" ? 1 : 0.5 }}
          onClick={() => setDisplay("kan-ban")}
        >
          Kanban
        </button>
        <button
          className={`calendar_header`}
          style={{ opacity: display === "calendar" ? 1 : 0.5 }}
          onClick={() => setDisplay("calendar")}
        >
          Calendar
        </button>
      </div>

      {display === "kan-ban" ? (
       
        <div className="quercus">
        
          <div className="add_task_new_container">
            <button
              className="add_task_container"
              onClick={() => setVisible(true)}
            >
              Add Task
            </button>
          </div>
          <div className="task_and_routines_container">
            <div className="board_containers">
              <div className="todo_container">To Do</div>

              {taskList && taskList.length > 0 ? (taskList
                .filter((item) => item.status === "todo")
                .map((item, index) => (
                  <div key={index.toString()} className="new_task">
                    <div className="task_header">
                      <button
                        className="move-button"
                        onClick={() => deleteTask(item.task_id)}
                      >
                        <FontAwesomeIcon
                          icon={faCircleXmark}
                          size="2xl"
                          style={{ color: "ivory" }}
                        />
                      </button>
                      <span className="task-text">{item.title}</span>
                    </div>
                    <div className="task_details">
                      <button
                        className="move-button"
                        onClick={() => changeStatus(item.task_id, "doing")}
                      >
                        <FontAwesomeIcon
                          icon={faCircleChevronRight}
                          size="2xl"
                          style={{ color: "ivory" }}
                        />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no_tasks"></div>
              )}
            </div>

            <div className="board_containers">
              <div className="doing_container">Doing</div>

              {taskList && taskList.length > 0 ? (taskList
                .filter((item) => item.status === "doing")
                .map((item, index) => (
                  <div key={index.toString()} className="new_task">
                    <div className="task_header">
                      <button
                        className="move-button"
                        onClick={() => {changeStatus(item.task_id, "done"); playSound();}}
                      >
                        <FontAwesomeIcon
                          icon={faSquareCheck}
                          size="2xl"
                          style={{ color: "ivory" }}
                        />
                      </button>
                      <span className="task-text">{item.title}</span>
                    </div>
                    <div className="task_details">
                      <button
                        className="move-button"
                        onClick={() => changeStatus(item.task_id, "todo")}
                      >
                        <FontAwesomeIcon
                          icon={faCircleChevronLeft}
                          size="2xl"
                          style={{ color: "ivory" }}
                        />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-tasks"></div>
              )}
            </div>

            <div className="board_containers">
              <div className="done_container">Done</div>

              {taskList && taskList.length > 0 ? (taskList
                .filter((item) => item.status === "done")
                .map((item, index) => (
                  <div key={index.toString()} className="new_task">
                    <div className="task_header_done">
                      <button
                        className="move-button"
                        onClick={() => changeStatus(item.task_id, "doing")}
                      >
                        <FontAwesomeIcon
                          icon={faCircleChevronLeft}
                          size="2xl"
                          style={{ color: "ivory" }}
                        />
                      </button>
                      <span className="task-text">{item.title}</span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-tasks"></div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="quercus">
         
            
          <div className="quercus">
          <button
          className={`do_date_header`}
          style={{ opacity: calendar_view === "do_date" ? 1 : 0.5 }}
          onClick={() => setCalendarView("do_date")}
        >
            DO date
        </button>
        <button
          className={`due_date_header`}
          style={{ opacity: calendar_view === "due_date" ? 1 : 0.5 }}
          onClick={() => setCalendarView("due_date")}
        >
          DUE date
        </button>
          </div>

          {calendar_view === "due_date" ? (
            <div className="quercus">
            <div className="construct">
              <div className="calendar_container">
                <div className="month_name">{monthNames[selectedMonth]}</div>
                <button className="month_button" onClick={previousMonth}>{"<"}</button>
                <button className="month_button" onClick={nextMonth}>{">"}</button>
              </div>
              <div className="calendar_container">
                <div className="day_container">
                  <div className="day_titles">Mon</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Tues</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Wed</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Thurs</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Fri</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Sat</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Sun</div>
                </div>
              </div>
              {renderCalendar("due_date")}
            </div>
          </div>
        
          ) : (
            <div className="quercus">
            <div className="construct">
              <div className="calendar_container">
                <div className="month_name">{monthNames[selectedMonth]}</div>
                <button className="month_button" onClick={previousMonth}>{"<"}</button>
                <button className="month_button" onClick={nextMonth}>{">"}</button>
              </div>
              <div className="calendar_container" >
                <div className="day_container">
                  <div className="day_titles">Mon</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Tues</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Wed</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Thurs</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Fri</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Sat</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Sun</div>
                </div>
              </div>
              {renderCalendar("do_date")}
            </div>
          </div>
          )}
       
        </div>
      )}
    </div>
  );
}
