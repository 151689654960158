import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";
import Modal from "react-modal";
import { BASE_URL } from "./Base_url";
import useToken from "./TokenContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket
} from "@fortawesome/free-solid-svg-icons";
import { set } from "date-fns";



const screenWidth = window.innerWidth;
const numColumns = 3;
const imageSize = (screenWidth / numColumns)*0.75;

export default function PersonalProfile({setIsAuthenticated}) {
  const navigate = useNavigate();
  const { token, refreshToken, profile, pomos_this_week, do_today  } = useToken();
  const [display, setDisplay] = useState("wotd");
  const [classes, setClasses] = useState(null);
  const [wotd, setWOTD] = useState(null);
  const [visible, setVisible] = useState(false);
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [image, setImage] = useState(null);
  const [ispublic, setIsPublic] = useState(true);
  const [modalView, setModalView] = useState("addWOTD");
  const [confirmDelete, setConfirmDelete] = useState(false);
  Modal.setAppElement('#root');

  const pickImage = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onloadend = () => {
      setImage({
        uri: reader.result,
        base64: reader.result.split(',')[1],
        fileName: file.name,
      });
    };
  
    if (file) {
      reader.readAsDataURL(file);
    }
  };
    


  const upload_image = async () => {
    if (!image?.base64) {
        alert('No image selected');
        return;
      }
    
      const data = {
        image: image.base64, // Use the Base64 data directly
        filename: image.fileName,
      };

    let jsonData;
    try {
      jsonData = JSON.stringify(data);
    } catch (error) {
      console.error("Error creating JSON from data:", error);
      return null;
    }

    const response = await fetch(`${BASE_URL}/api/wotd/image`, {
      method: "POST",
      body: jsonData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 401) {
      try {
        await refreshToken(); 
        
      } catch (error) {
        
          // Handle token refresh failure
          throw error;
      }
    }

    console.log(response);

    const responseBody = await response.json();
    console.log(responseBody);

    if (response.status === 200) {
      return responseBody;
    } else {
      alert("There was an error uploading the image.");
      return null;
    }
  };

  const submitData = async () => {
    const image_url = await upload_image();

    let wotdObject = {
      username: profile[0].username,
      wotd_id: "ha",
      post_link: image_url,
      description: description,
      tags: tags.split(" ").map((tag) => tag.trim()),
      likes: 0,
      public: ispublic,
      creation_date: "ha",
    };

    let jsonData;
    try {
      jsonData = JSON.stringify(wotdObject);
    } catch (error) {
      console.error("Error creating JSON from data:", error);
      return null;
    }

    try {
      let response = await fetch(`${BASE_URL}/api/wotd`, {
        method: "POST",
        body: jsonData,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseBody = await response.json();
      console.log(responseBody);
      setIsPublic(true);
      setDescription("");
      setTags("");
      setImage(null);
      setVisible(false);
      setWOTD([wotdObject, ...wotd]);
      return responseBody;
    } catch (error) {
      console.error(error);
      alert("There was an error uploading the wotd.");
      return null;
    }
  };

  const logOut = async () => {
    try {
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
      setIsAuthenticated(false);

    } catch (error) {
      // Handle token refresh failure
      throw error;
    }
  }; 

  const deleteAccount = async () => {
    try {
      let response = await fetch(`${BASE_URL}/api/user/delete?username=${profile[0].username}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      logOut();
    } catch (error) {
      console.error(error);
      alert("There was an error deleting your account.");
      return null;
    }
  }

  

  useEffect(() => {
    if (profile && profile.length > 0) {
      fetch(`${BASE_URL}/api/class?username=${profile[0].username}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((fetchedData) => setClasses(fetchedData))
        .catch((error) => console.error("Error:", error));
    }
  }, [profile]);

  useEffect(() => {
    if (profile && profile.length > 0) {
      fetch(`${BASE_URL}/api/wotd?username=${profile[0].username}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((fetchedData) => setWOTD(fetchedData))
        .catch((error) => console.error("Error:", error));
    }
  }, [profile]);


  return (
    <div style={{ flexGrow: 1, backgroundColor: "#2b2b2b" }}>
      <Modal isOpen={visible} onRequestClose={() => {setVisible(false); setModalView("addWOTD"); setConfirmDelete(false)}} style = {{overlay: {
      backgroundColor: 'transparent'
    },
    content: {
      backgroundColor: 'transparent',
      border: 'none',
      width: '10%',
      height: '10%',
      alignSelf: 'center',
      
    }}}>
        {modalView === "addWOTD" ? (
        <>
        <div className="add_wotd">
        
      <div  className="image_box" onClick={() => document.getElementById('fileInput').click()}>
        {image ? (
            <img src={image.uri} alt="Picked" style={{ width: "100%", height: "100%", borderRadius: 10 }} />
        ) : (
            <p className="text">Pick Image</p>
        )}
        <input
            id="fileInput"
            type="file"
            style={{ display: 'none' }}
            onChange={pickImage}
            accept="image/*"
        />
        </div>

       
        
        <input
            id="description"
            type="text"
            maxLength="40"
            placeholder="Description"
            value={description}
            onChange={(e) => {setDescription(e.target.value)}}
            className="description"
        />
       

        
        
        <input
            id="tags"
            type="text"
            placeholder="Tags"
            
            maxLength="20"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            className="description"
        />
      

        <div className="iwanttoslammyheadagainstthewall">
          <button
            className={ispublic ? "sign_in_butt_public" : "sign_in_butt_private"}
            onClick={() => setIsPublic(!ispublic)}
          >
            <p className="text">{ispublic ? "Public" : "Private"}</p>
          </button>
          <button
            className="sign_in_butt"
            onClick={() => {
              submitData();
              setVisible(false);
            }}
          >
            <p className="text">Submit</p>
          </button>
        </div>
        </div>
        </> ):(
          <>
          <div className="sign_out"> 
          {!confirmDelete ? (
          <button className="sign_out_butt" onClick={() => {logOut()}}>
            <p >Log Out</p>
          </button>) : (
            <p className="text" >Confirm Deletion</p>
          )}
          
          <button className="delete_butt" onClick={() => {!confirmDelete? (setConfirmDelete(true)):(deleteAccount())}}>
            <p >Delete Account</p>
          </button>
          
          </div>
          </>
        )
        }
      </Modal>
      <div className="container2">
        {profile && (
          <div className = "iwishwenevermet">
            <img className="profile_pic" src={profile[0].profile_picture} alt="Profile" />
           
            <div className="drain">
                
              <p className="username">{profile[0].username}</p>
                <p className="text">Pomodoros: {pomos_this_week}</p>
                
            </div>
            <div className="pain">
                
            <button 
              className="sign_in_butt" 
              onClick={() => {setModalView("sigh_out"); setVisible(true)}}> 
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                size="2xl"
                style={{ color: "ivory" }}
              />
            </button>
                
            </div>
          </div>
        )}
        <div className="aha">
          <div className="current_tasks">
            <p className="current_task_display">Todays Tasks</p>
            {do_today && do_today.length > 0 ? (
              do_today.map((task, index) => (
                <p className="actual_current_tasks"key={index}>
                  {task.title}
                </p>
              ))
            ) : (
              <p className="actual_current_tasks">None</p>
            )}
          </div>
         
            <div className="waste">
              <button onClick={() => {setVisible(true); setModalView("addWOTD"); }} style={{ backgroundColor: "#2b2b2b", border: "none" }}>
                <div style={{ width: imageSize, height: imageSize, padding: 2, backgroundColor: "grey", borderRadius: 10 }}>
                  {/* Add content to the div */}
                </div>
              </button>
              {wotd &&
                wotd.map((wot, index) => (
                  <div key={index} style={{ width: imageSize, height: imageSize, padding: 2 }}>
                    <img
                        style={{ width: "100%", height: "100%", borderRadius: 10 }}
                        src={wot.post_link}
                        alt="WOTD"
                        />
                  </div>
                ))}
            </div>
        </div>
      </div>
    </div>
  );
}