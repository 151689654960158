import React, { useState, useEffect, useContext, useRef, } from "react";
import useToken  from "./TokenContext";
import { BASE_URL } from "./Base_url.js";
import { useAudio } from './AudiContext';
import { usePomodoro } from './PomodoroContext';

export default function Pomodoro() {
  const { mainTimer, setMainTimer, breakTimer, setBreakTimer, isRunning, setIsRunning, isBreak, setIsBreak, pomostoday, canvasRef, progress, minutes, seconds } = usePomodoro();

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = centerX * 0.8;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the background circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    ctx.fillStyle = "#2b2b2b";
    ctx.fill();

    // Draw the countdown arc
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, radius, -Math.PI / 2, progress * 2 * Math.PI - Math.PI / 2);
    ctx.lineTo(centerX, centerY);
    ctx.fillStyle = isBreak ? "#F3B04C" : "#B95A34";
    ctx.fill();
  }, [progress, isBreak]);

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "#2b2b2b", height: "100%" }}>
      <div style={{ fontSize: 25,  color: 'ivory', fontWeight: 'bold', margin: '0' }}>Pomodoros: {pomostoday}</div>
      <div style={{ width: 500, height: 500, position: "relative" }}>
        <canvas ref={canvasRef} width={500} height={500} />
        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <h1 style={{ fontSize: 48, color: 'ivory', fontWeight: 'bold' }}>
            {`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
          </h1>
        </div>
      </div>
      <button
        style={{ fontSize: 25,  color: 'ivory', fontWeight: 'bold', backgroundColor: "transparent", border: "none", cursor: "pointer" }}
        onClick={() => {
          if (isRunning) {
            localStorage.removeItem('startTime');
            localStorage.removeItem('isBreak');
            setIsRunning(false);
            if (!isBreak) {
              setMainTimer(25 * 60);
            } else {
              setBreakTimer(5 * 60);
              setIsBreak(false);
            }
          } else {
            localStorage.setItem('startTime', Date.now());
            localStorage.setItem('isBreak', isBreak);
            setIsRunning(true);
          }
        }}
      >
        {isRunning ? "Stop" : "Start"}
      </button>
    </div>
  );
}