import React, { useState, useContext, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import PersonalProfile from "./PersonalProfile";
import TaskView from "./TaskView";
import Pomodoro from "./Pomodoro";
import SignIn from "./SignIn";
import WebsiteDisplay from "./WebsiteDisplay";
import useToken, {TokenProvider} from "./TokenContext";
import { AudioProvider } from './AudiContext';
import { PomodoroProvider } from './PomodoroContext';
import simpleLogo from "./simpleLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleArrows, faUser, faCheckSquare, faClock } from "@fortawesome/free-solid-svg-icons";



export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);

  // Check for token in local storage on component mount


  useEffect(() => {
    const token = localStorage.getItem('refreshToken');
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      const { exp } = JSON.parse(jsonPayload);
      const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds
  
      if (currentTimestamp < exp) {
        setIsAuthenticated(true);
      } else {
        // Token is expired
        setIsAuthenticated(false);
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
      }
    }
  }, []);






  return (

    <TokenProvider value={{ isAuthenticated, setIsAuthenticated }}>
    {!isAuthenticated ? (
      <SignIn onSignIn={() => setIsAuthenticated(true)} />
    ) : (
      <div>
      <AudioProvider>
      <PomodoroProvider>
      
        <Router>
          <div className="container">
            <nav>
              <ul style={{ display: "flex", justifyContent: "space-around", listStyle: "none" }}>

              <li>
                <NavLink to="/website" style={{ opacity:  1 }} >
                  <img src={simpleLogo} width="40px" height="40px"/>
                </NavLink>
                </li>

                <li>
                <NavLink to="/profile" style={{ opacity: 0.5 }} >
                    <FontAwesomeIcon icon={faUser} size="2x" color="gray" />
                </NavLink>
                </li>
                <li>
                <NavLink to="/tasks" style={{ opacity: 0.5 }} >
                    <FontAwesomeIcon icon={faCheckSquare} size="2x" color="gray" />
                </NavLink>
                </li>
                <li>
                <NavLink to="/pomodoro" style={{ opacity: 0.5 }} >
                    <FontAwesomeIcon icon={faClock} size="2x" color="gray" />
                </NavLink>
                </li>
              </ul>
            </nav>

            <Routes>
              <Route exact path="/" element={<PersonalProfile setIsAuthenticated={setIsAuthenticated} />} />
              <Route path="/profile" element={<PersonalProfile setIsAuthenticated={setIsAuthenticated} />} />
              <Route path="/tasks" element={<TaskView />} />
              <Route path="/pomodoro" element={<Pomodoro />} />
              <Route path="/website" element={<WebsiteDisplay />} />
            </Routes>
          </div>
        </Router>
        </PomodoroProvider>
        </AudioProvider>
        </div>
      )}
    </TokenProvider>
  );
}