import React from'react';
import I_know_its_an_image_im_rushed from "./I_know_its_an_image_im_rushed.png";


export default function WebsiteDisplay() {
    return(
        <div>
          <img src={I_know_its_an_image_im_rushed}alt="Description" width="100%" height="100%" />
        </div>
    )
    };