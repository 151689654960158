import React, { createContext, useRef, useContext } from 'react';
import pomoSound from "./level-up-191997.mp3"; // Ensure this import is here

const AudioContext = createContext();

export function useAudio() {
  return useContext(AudioContext);
}

export const AudioProvider = ({ children }) => {
  const audioRef = useRef(null);

  const playSound = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  return (
    <AudioContext.Provider value={{ audioRef, playSound }}>
      <audio ref={audioRef} src={pomoSound} hidden />
      {children}
    </AudioContext.Provider>
  );
};